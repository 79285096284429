<script lang="ts" setup>
interface Events {
    (e: 'previous'): void;
    (e: 'next'): void;
}

const emit = defineEmits<Events>()
</script>

<template>
    <div
        class="flex border border-uk-orange rounded-xl text-uk-orange overflow-hidden"
    >
        <button
            class="p-2 lg:p-3 hover:bg-uk-orange hover:text-white transition-colors"
            @click="emit('previous')"
        >
            <IconChevron class="rotate-180" />
        </button>
        <button
            class="p-2 lg:p-3 hover:bg-uk-orange hover:text-white transition-colors"
            @click="emit('next')"
        >
            <IconChevron />
        </button>
    </div>
</template>

<style scoped></style>
